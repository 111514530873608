<template>
  <div class="flex flex-col xl:flex-row gap-8">
    <DiscoverFilter class="w-full xl:max-w-xs" @queryChange="queryChange">
      <button aria-label="Save CSV filtered data" @click="exportFilteredDataCsv">
        <Icon icon="save" width="30" height="30" />
      </button>
    </DiscoverFilter>

    <List
      class="flex list--campaigns"
      :sort="[
        {
          label: 'Username',
          prop: 'date',
        },
        {
          label: 'Email',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Language',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Country',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Joined campaigns',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: 'Follower count',
          prop: 'streamerAmount',
          align: 'center',
        },
        {
          label: '',
          prop: '',
        },
      ]"
    >
      <li v-for="(contentCreator, contentCreatorIndex) of visibleUsers" :key="contentCreatorIndex" class="list__item">
        <div class="list__item-img">
          <img
            v-if="contentCreator.username"
            :alt="contentCreator.username"
            loading="lazy"
            :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${contentCreator.username}/avatar.png`"
            @error="profileImageFallback"
          />
        </div>

        <div class="list__item-content">
          <div class="list__item-col" data-col="1">
            <Button
              class="mb-2 text-sm font-medium capitalize ellipsis"
              :url="`/profile/${contentCreator.username}`"
              kind="link"
              :label="contentCreator.username"
              style="text-transform: capitalize !important"
              color="white"
              :capitalize="true"
            />

            <div class="flex flex-col">
              <p v-if="contentCreator.joinedDate" class="text-xs ellipsis">
                Joined date:
                {{ new Date(contentCreator.joinedDate) | moment("Do MMM YYYY") }}
              </p>

              <p v-if="contentCreator.lastLogin" class="text-xs ellipsis">
                Last seen:
                {{ new Date(contentCreator.lastLogin) | moment("Do MMM YYYY") }}
              </p>
            </div>
          </div>

          <div class="items-center list__item-col" data-col="2">
            <Button
              class="mb-2 text-sm font-medium capitalize button ellipsis"
              kind="link"
              :capitalize="true"
              color="white"
              :label="contentCreator.email || 'N/A'"
            />
          </div>

          <div class="items-center list__item-col" data-col="3">
            <Button
              class="mb-2 text-sm font-medium capitalize button ellipsis"
              kind="link"
              color="white"
              :capitalize="true"
              :label="contentCreator.broadcastingLanguage || 'N/A'"
            />
          </div>

          <div class="items-center list__item-col" data-col="4">
            <Button
              class="mb-2 text-sm font-medium capitalize button ellipsis"
              kind="link"
              color="white"
              :capitalize="true"
              :label="contentCreator.country || 'N/A'"
            />
          </div>

          <div class="items-center list__item-col" data-col="5">
            <Button
              class="mb-2 text-sm font-medium capitalize button ellipsis"
              kind="link"
              color="white"
              :capitalize="true"
              :label="contentCreator && contentCreator.joinedCampaigns ? `${contentCreator.joinedCampaigns}` : '0'"
            />
          </div>

          <div class="items-center list__item-col" data-col="6">
            <Button
              class="mb-2 text-sm font-medium capitalize button ellipsis"
              kind="link"
              color="white"
              :capitalize="true"
              :label="contentCreator && contentCreator.followerCount ? `${contentCreator.followerCount}` : '0'"
            />
          </div>

          <div class="list__item-col" data-col="0">
            <Dropdown
              :position="['right']"
              :items="[
                {
                  label: 'View profile',
                  url: `/profile/${contentCreator.username}`,
                },
                {
                  label: 'Withdraw history',
                  id: `${contentCreator._id}`,
                },
                {
                  label: 'Personal Information',
                  id: `${contentCreator._id}`,
                },
              ]"
              :is-show-more="true"
              @change="dropdownClick"
            />
          </div>
        </div>
      </li>
    </List>

    <Modal v-if="showPersonalInfoModal" @closeModal="closePersonalInfoModal">
      <Tile class="p-8 mb-10">
        <Title size="small" title="Personal information" />

        <p>Fullname: {{ information.fullname }}</p>
        <p>Email: {{ information.email }}</p>
        <p>Address: {{ information.address }}</p>
        <p>City: {{ information.city }}</p>
        <p>Postalcode: {{ information.postalCode }}</p>
        <p>Country: {{ information.country }}</p>

        <Title size="small" title="Identity" />

        <p>Type: {{ information.identity.type }}</p>
        <p>Value: {{ information.identity.value }}</p>

        <Title size="small" title="Banking" />

        <p>IBAN: {{ information.banking.iban }}</p>
        <p>Bic: {{ information.banking.bic }}</p>
      </Tile>
    </Modal>

    <Modal v-if="showStreamerWithdrawHistory" @closeModal="closeStreamerWithdrawHistory">
      <Tile class="p-8 mb-10">
        <div class="grid grid-cols-3 mb-4">
          <KPI label="Available for withdraw" :value="`€${(streamerWallet.withdrawable || 0).toFixed(2)}`" />

          <KPI label="Locked pending campaign completion" :value="`€${(streamerWallet.locked || 0).toFixed(2)}`" />

          <KPI label="Total withdrawed" :value="`€${(streamerWallet.total_withdrawed || 0).toFixed(2)}`" />

          <KPI label="Total earned" :value="`€${(streamerWallet.total_earned || 0).toFixed(2)}`" />
        </div>

        <Title size="small" title="History" />

        <div v-if="streamerWallet && streamerWallet.withdrawHistory && streamerWallet.withdrawHistory.length">
          <div class="grid grid-cols-2">
            <Button class="m-auto">Amount</Button>
            <Button class="m-auto">Date</Button>
          </div>

          <div
            v-for="(withdrawal, withdrawalIndex) of streamerWallet.withdrawHistory"
            :key="withdrawalIndex"
            class="grid grid-cols-2 pt-2 pb-2"
            style="border-top: 1px solid rgb(0 0 0 / 20%)"
          >
            <p class="m-auto">
              {{ `€${withdrawal.amount}` }}
            </p>

            <p class="m-auto">
              {{ new Date(withdrawal.createdDate).toDateString() }}
            </p>
          </div>
        </div>

        <p v-else class="text-center">No withdraws yet</p>
      </Tile>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { KPI, Tile, Title, Button, Icon } from "cavea-design-system";
import { mapGetters } from "vuex";
import jsonToCsv from "@/lib/helpers/jsonToCsv";

export default {
  name: "AdminDiscover",

  metaInfo: {
    title: "Discover",
  },

  components: {
    List: () => import("@/modules/List"),
    Title,
    Button,
    Dropdown: () => import("@/components/Dropdown"),
    Modal: () => import("@/components/Modal"),
    Tile,
    DiscoverFilter: () => import("@/components/DiscoverFilter"),
    KPI,
    Icon,
  },

  data() {
    return {
      fetchingUserList: false,
      userList: [],
      isScrollable: false,
      userFilter: { username: "", minFollowerCount: 0, maxFollowerCount: 9999999, languages: [], countries: [] },
      shownUsers: 0,

      showPersonalInfoModal: false,
      information: {
        fullname: "",
        email: "",
        address: "",
        city: "",
        postalCode: "",
        banking: {
          iban: "",
          bic: "",
        },
        identity: {
          type: "",
          value: "",
        },
      },

      showStreamerWithdrawHistory: false,
      streamerWallet: {
        locked: 0,
        withdrawable: 0,
        total_earned: 0,
        total_withdrawed: 0,
        earned_today: 0,
        earned_last_7: 0,
        earned_last_30: 0,
        earned_last_365: 0,
        withdrawHistory: null,
      },
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    visibleUsers() {
      return this.userList?.length ? this.userList?.slice(0)?.splice(0, this.shownUsers) ?? [] : [];
    },

    filterQueryString() {
      let queryString = "";

      if (this.userFilter?.username) {
        queryString += `&username=${this.userFilter?.username}`;
      }

      if (
        this.userFilter?.minFollowerCount !== null &&
        typeof parseInt(this.userFilter?.minFollowerCount, 10) === "number" &&
        this.userFilter?.minFollowerCount !== 0
      ) {
        queryString += `&minFollowers=${this.userFilter?.minFollowerCount}`;
      }
      if (
        this.userFilter?.maxFollowerCount !== null &&
        typeof parseInt(this.userFilter?.maxFollowerCount, 10) === "number" &&
        this.userFilter?.maxFollowerCount !== 9999999
      ) {
        queryString += `&maxFollowers=${this.userFilter?.maxFollowerCount}`;
      }

      if (this.userFilter?.languages?.length) {
        queryString += `&language=${this.userFilter?.languages?.join("&language=")?.toLowerCase()}`;
      }

      if (this.userFilter?.countries?.length) {
        queryString += `&country=${this.userFilter?.countries?.join("&country=")?.toLowerCase()}`;
      }

      return queryString;
    },
  },

  async created() {
    this.fetchUserList();
  },

  methods: {
    async fetchUserList() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/users?skip=${this.shownUsers}${this.filterQueryString}`;

      const contentCreators = await axios
        .get(encodeURI(URL))
        .then((res) => res?.data?.contentCreators || [])
        .catch((err) => {
          console.error("Error fetching user list", err);
          return [];
        });

      this.fetchingUserList = false;

      this.userList.push(...contentCreators);
      if (contentCreators?.length) {
        this.isScrollable = true;
        this.loadMoreStreamers();

        this.shownUsers += 25;
      } else {
        this.isScrollable = false;
      }
    },

    loadMoreStreamers() {
      if (this.isScrollable) {
        window.onscroll = () => {
          if (
            !this.fetchingUserList &&
            this.shownUsers < this.userList.length &&
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
          ) {
            this.shownUsers += 25;
          }
        };
      } else {
        window.onscroll = (e) => {
          e.preventDefault();
        };
      }
    },

    async queryChange(query) {
      if (_.isEqual(this.userFilter, query)) return;

      this.userList = [];

      this.userFilter = query;

      this.fetchingUserList = true;
      this.shownUsers = 0;
      this.fetchUserList();
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/adlabgg/avatar.png";

      if (event.target.alt) {
        axios.get(`${process.env.VUE_APP_AVATAR_SERVICE}/avatar/twitch/${event.target.alt}`);
      }
    },

    async dropdownClick(e) {
      if (e?.item?.label?.toLowerCase() === "personal information" && e?.item?.id) {
        this.showPersonalInfoModal = true;
        this.getBankingInformation(e.item.id);
      } else if (e?.item?.label?.toLowerCase() === "withdraw history" && e?.item?.id) {
        this.showStreamerWithdrawHistory = true;
        this.getContentCreatorWithdrawHistory(e.item.id);
        this.getContentCreatorWallet(e.item.id);
      }
    },

    async getBankingInformation(contentCreatorId) {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/banking-information/${this.getUserInfo._id}/${contentCreatorId}`;

      const response = await axios
        .get(URL)
        .then((res) => res.data)
        .catch((err) => {
          console.error("getBankingInformation error", err);
          return err.response.data;
        });

      if (response?.information) {
        this.information = response?.information;
      }
    },

    closePersonalInfoModal() {
      this.showPersonalInfoModal = false;

      this.information = {
        fullname: "",
        email: "",
        address: "",
        city: "",
        postalCode: "",
        banking: {
          iban: "",
          bic: "",
        },
        identity: {
          type: "",
          value: "",
        },
      };
    },

    closeStreamerWithdrawHistory() {
      this.showStreamerWithdrawHistory = false;

      this.streamerWallet = {
        locked: 0,
        withdrawable: 0,
        total_earned: 0,
        total_withdrawed: 0,
        earned_today: 0,
        earned_last_7: 0,
        earned_last_30: 0,
        earned_last_365: 0,
        withdrawHistory: null,
      };
    },

    async getContentCreatorWithdrawHistory(contentCreatorId) {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/withdraws/streamer/${contentCreatorId}`;

      this.streamerWallet.withdrawHistory = await axios
        .get(URL)
        .then((res) => res?.data?.withdrawHistory || [])
        .catch((error) => {
          console.error("Error getting withdraw history", error);
          return [];
        });
    },

    async getContentCreatorWallet(contentCreatorId) {
      const walletData = await axios
        .get(`${process.env.VUE_APP_API_URL}/payments/influencers/${contentCreatorId}/wallet`)
        .then((res) => res?.data)
        .catch((err) => {
          console.error("getWallet error", err);

          return {
            withdrawable: 0,
            total_earned: 0,
            total_withdrawed: 0,
          };
        });

      this.streamerWallet.locked = walletData?.locked || 0;
      this.streamerWallet.withdrawable = walletData?.withdrawable || 0;
      this.streamerWallet.total_earned = walletData?.total_earned || 0;
      this.streamerWallet.total_withdrawed = walletData?.total_withdrawed || 0;
    },

    async exportFilteredDataCsv() {
      await jsonToCsv(this.userList, `netwrk_discover_filtered_${new Date().toISOString().split("T")[0]}.csv`);
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
